.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 700px;
}

.container Button {
    border: 2px solid var(--primary-text);
    color: var(--primary-text);
    background-color: #fff;
}

.boxContainer {
    display: flex;
    justify-content: space-evenly;
}

.img {
    width: 50%;
}

.textBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    height: 260px;
}

.h1 {
    font-size: 74px;
}

.h2 {
    font-size: 42px;
}

.link {
    position: relative;
    padding: 14px 5px 14px 66px;
    width: 360px;
    height: 80px;
    border: 3px solid var(--dark);
    border-radius: 10px;
    background: linear-gradient(to right, #0059bd 15%, #ffffff 15%, #ffffff 100%);
    text-decoration: none;
    color: var(--dark);
    font-size: 40px;
    font-family: Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    transition: color .3s linear, border .3s linear;
    z-index: 0;
}

.link:hover {
    color: #ff8400;
    border: 3px solid #ff8400;
}

.link:before,
.link:after {
    display: block;
    position: absolute;
}

.link:before {
    content: '';
    background-image: url("../../images/stars.webp");
    background-size: cover;
    top: 8px;
    left: 11px;
    width: 30px;
    height: 30px;

}

.link:after {
    content: 'PL';
    top: 46px;
    left: 15px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
}